import { useEffect, useState } from 'react';
import useBem from '@indicia/use-bem';
import { InfinitySpin } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import http from '../../shared/utils/http-client';
import Page from '../../shared/components/layout/page/page';
import ErrorContainer from '../error/pages/component/error-container';

import './checkout.scss';

const Checkout = () => {
  const { t } = useTranslation('common', { keyPrefix: 'checkout' });
  const { bemClassName } = useBem('checkout-page');
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  useEffect(() => {
    http
      .get('/payments/has-succeeded')
      .then(({ data }) => {
        const navigateUrl = data ? '/booking-confirmation' : '/create-booking?redirect=checkout';
        navigate(navigateUrl);
      })
      .catch(() => setError(true))
      .finally(() => setLoading(false));
  }, []);

  return (
    <Page className={bemClassName()}>
      {loading && (
        <div className={bemClassName('information-container')}>
          <h3>{t('success.title')}</h3>
          <div className={bemClassName('loader-container')}>
            <InfinitySpin />
          </div>
        </div>
      )}

      {error && (
        <ErrorContainer
          className={bemClassName('error-container')}
          title={t('error.title')}
          description={t('error.description')}
          buttonText={t('error.button-text')}
          buttonAction={() => navigate('/')}
        />
      )}
    </Page>
  );
};

export default Checkout;
