import React, { FC, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useBem from '@indicia/use-bem';
import { useNavigate } from 'react-router-dom';

import Page from '../../../../shared/components/layout/page/page';
import { PageThemeEnum } from '../../../../shared/components/layout/page/types';
import { Button } from '../../../../shared/components';
import useBooking from '../../../bookings/data/hooks/useBooking';
import useBookingFormContext from '../../../bookings/data/context/use-booking-form-context';
import { BookingStatus } from '../../../bookings/data/utils/enums';

import './welcome.scss';

const Welcome: FC = () => {
  const { bemClassNames, bemClassName } = useBem('welcome-page');
  const [agreedToTermsAndConditions, setAgreedToTermsAndConditions] = useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data, error } = useBooking();
  const { updateBooking } = useBookingFormContext();
  const checkboxId = 'terms-and-conditions-checkbox';

  useEffect(() => {
    if (data) {
      updateBooking(data);
      if (data.bookingStatus === BookingStatus.PaymentSucceeded) {
        navigate('/booking-confirmation');
      }
    }
  }, [data]);

  if (error) {
    navigate('/error');
  }

  const downloadFile = (file: Blob, fileName: string) => {
    const aElement = document.createElement('a');
    aElement.setAttribute('download', fileName);
    const href = URL.createObjectURL(file);
    aElement.href = href;
    aElement.setAttribute('target', '_blank');
    aElement.click();
    URL.revokeObjectURL(href);
  };

  const downloadDocument = () => {
    fetch(`/assets/documents/nato-2025-terms-and-conditions.pdf`, {
      method: 'get',
      mode: 'no-cors',
      referrerPolicy: 'no-referrer',
    })
      .then((res) => res.blob())
      .then((res) => {
        downloadFile(res, 'nato-2025-terms-and-conditions.pdf');
      });
  };

  return (
    <Page
      theme={PageThemeEnum.Default}
      className={bemClassName()}
    >
      <div className={bemClassName('container')}>
        <div className={bemClassNames([bemClassName('content'), bemClassName('content', 'welcome')])}>
          <h1 className={bemClassName('title')}>{t('welcome-page.title')}</h1>
        </div>
        <div className={bemClassNames([bemClassName('content'), bemClassName('content', 'introduction')])}>
          <h1 className={bemClassName('title')}>{t('introduction-page.title')}</h1>
          <Trans
            i18nKey="introduction-page.content"
            components={{
              p: <p />,
              br: <br />,
              ul: <ul />,
              li: <li />,
            }}
          />
          <div className={bemClassName('terms-and-conditions')}>
            <input
              type="checkbox"
              id={checkboxId}
              name={checkboxId}
              checked={agreedToTermsAndConditions}
              onChange={() => setAgreedToTermsAndConditions(!agreedToTermsAndConditions)}
            />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <label htmlFor={checkboxId}>
              <Trans
                i18nKey="introduction-page.terms-and-conditions-text"
                components={{
                  link: (
                    <button
                      onClick={() => downloadDocument()}
                      type="button"
                    >
                      {t('introduction-page.terms-and-conditions-link')}
                    </button>
                  ),
                }}
              />
            </label>
          </div>
          <div className={bemClassName('action')}>
            <Button
              disabled={!agreedToTermsAndConditions}
              onClick={() => navigate('/create-booking')}
            >
              {t('introduction-page.button')}
            </Button>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Welcome;
